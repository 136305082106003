import classes from "./UserMediaCheck.module.css";
import React, { useContext, useEffect, useState, useRef } from "react";
import Modal, { ModalBody } from "../../components/Modal/Modal";
import { IconVideo, IconMic, IconDropdown } from "../../utils/Icons";
import { AppContext } from "../../context/AppContext";
import Dropdown from "../../utils/Dropdown/Dropdown";
import AudioStrength from "./AudioStrength";
import AgoraRTC from "agora-rtc-sdk";

export default function UserMediaCheck(props) {
	const audioRef = useRef(null);
	const videoRef = useRef(null);
	const appContext = useContext(AppContext);

	let [activeCameraId, setCameraId] = useState("");
	let [activeMicId, setMicId] = useState("");
	let [cameraDevicesList, setCameraList] = useState([]);
	let [micDevicesList, setmicList] = useState([]);
	let [client, setClient] = useState(null);
	let [stream, setStream] = useState(null);

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const prevId = usePrevious({ activeCameraId, activeMicId, stream });

	useEffect(() => {
		let newclient = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
		setClient(newclient);
	}, []);

	useEffect(() => {
		if (client) {
			getDevices();
		}
	}, [client]);

	useEffect(() => {
		if (activeMicId) {
			getDevices();
		}
	}, [activeMicId]);

	useEffect(() => {
		if (activeCameraId) {
			getDevices();
		}
	}, [activeCameraId]);

	useEffect(() => {
		if (prevId && prevId.stream) {
			prevId.stream.stop();
			prevId.stream.close();
		}
	}, [stream]);

	useEffect(() => {
		if (stream && !stream.isPlaying())
			stream.play(
				"mic-test",
				{
					fit: "contain",
					muted: true,
				},
				function (err) {
					if (err) {
						//toast.error("Unable to screenshare");
						console.error("Error while playing stream", err);
					}
				}
			);
	}, [stream]);

	const getCameraDevices = (cb) => {
		client.getCameras((cameras) => {
			console.log("listOfCameras", cameras);
			cameras.forEach((camera, i) => {
				let deviceId = camera.deviceId;
				if (i === 0) {
					setCameraId(deviceId);
				}
			});
			setCameraList(cameras);
		});
	};

	const getMicDevices = (cb) => {
		client.getRecordingDevices((mics) => {
			mics.forEach((mic, i) => {
				let name = mic.label.split("(")[0];
				let deviceId = mic.deviceId;
				if (i === 0) {
					setMicId(deviceId);
				}
			});
			setmicList(mics);
		});
	};

	const startStream = () => {
		if (!activeCameraId && !activeMicId) return;
		var mediaConstraints = {
			video: { width: 1280, height: 720 },
			audio: true,
		};
		if (activeCameraId) {
			mediaConstraints.video.deviceId = activeCameraId;
		}
		if (activeMicId) {
			mediaConstraints.audio = {
				deviceId: activeMicId,
			};
		}
		navigator.mediaDevices
			.getUserMedia(mediaConstraints)
			.then((mediaStream) => {
				var videoSource = mediaStream.getVideoTracks()[0];
				var audioSource = mediaStream.getAudioTracks()[0];
				// After processing videoSource and audioSource
				var localStream = AgoraRTC.createStream({
					video: true,
					audio: true,
					videoSource: videoSource,
					audioSource: audioSource,
					streamID: Math.random(),
					screen: false,
					mirror: false,
				});
				localStream.on("accessDenied", (evt) => {
					console.log("accessDenied-=-=", evt);
				});
				localStream.init(
					() => {
						if (window.audioLevel) {
							clearInterval(window.audioLevel);
						}
						setStream(localStream);
						window.stream = stream;
						// localStream.muteAudio()
						window.audioLevel = setInterval(() => {
							let audioLevel = localStream.getAudioLevel();
							console.log(`Local Stream Audio Level ${audioLevel}`);
							// this.emit('audio-level',audioLevel*10)
							appContext.setContext({
								audioLevel: Math.ceil(audioLevel * 10),
							});
						});
					},
					(err) => {
						console.error("error--------", err);
					}
				);
				localStream.on("accessAllowed", () => {});
			})
			.catch((err) => {
				console.error("errr ---- ", err);
			});
	};

	const getDevices = () => {
		if (!micDevicesList.length || !cameraDevicesList.length) {
			getMicDevices(startStream);
			getCameraDevices(startStream);
		}
		startStream();
	};

	const startLiveClass = () => {
		appContext.setContext({
			initAgora: true,
		});
		localStorage.setItem("activeCameraId", activeCameraId);
		localStorage.setItem("activeMicId", activeMicId);
		props.showHideModal(false);
	};

	const { setContext } = appContext;

	let activeCameraLabel = cameraDevicesList?.length
		? cameraDevicesList.find((ele) => ele.deviceId == activeCameraId)?.label
		: micDevicesList?.length
		? "Not Selected"
		: "Not Found";
	let activeMicLabel = micDevicesList?.length
		? micDevicesList.find((ele) => ele.deviceId == activeMicId)?.label
		: micDevicesList?.length
		? "Not Selected"
		: "Not Found";

	return (
		<Modal
			style={{
				zIndex: "111111",
				height: "337px",
				width: "570px",
				background: "#323134",
			}}
			hidden={false}
			toggle={() => {
				props.showHideModal(false);
			}}
		>
			<ModalBody style={{ maxHeight: "65vh", overflow: "visible" }}>
				<div className={classes.heading}>
					<span>Check your audio and video</span>
					<span></span>
				</div>
				<div className={classes.body}>
					<div className={classes.leftSide}>
						<div className={classes.video} id="mic-test" ref={videoRef}></div>
					</div>
					<div className={classes.rightSide}>
						<div className={classes.controls}>
							<IconVideo color="#7B7B7B" />
							<Dropdown
								name={
									<>
										<span className={classes.nameStyle}>
											{activeCameraLabel}&nbsp;
										</span>
									</>
								}
								ddnClass={classes.DropdownItems}
								btnClass={classes.btnStyle}
								items={
									<>
										{cameraDevicesList &&
											cameraDevicesList.length &&
											cameraDevicesList.map((camera) => {
												return (
													<li
														className={
															classes.listItems +
															" " +
															(activeCameraId === camera.deviceId
																? classes.selected
																: "")
														}
														onClick={() => {
															setCameraId(camera.deviceId);
														}}
													>
														{camera.label}
													</li>
												);
											})}
									</>
								}
							/>
						</div>
						<div className={classes.controls}>
							<IconMic color="#7B7B7B" />
							<Dropdown
								name={
									<>
										<span className={classes.nameStyle}>
											{activeMicLabel}&nbsp;
										</span>
									</>
								}
								ddnClass={classes.DropdownItems}
								btnClass={classes.btnStyle}
								items={
									<>
										{micDevicesList &&
											micDevicesList.length &&
											micDevicesList.map((mic) => {
												return (
													<li
														className={
															classes.listItems +
															" " +
															(activeMicId === mic.deviceId
																? classes.selected
																: "")
														}
														onClick={() => {
															setMicId(mic.deviceId);
														}}
													>
														{mic.label}
													</li>
												);
											})}
									</>
								}
							/>
						</div>
						<div className={classes.audioControls}>
							<audio
								style={{ visibility: "hidden" }}
								ref={audioRef}
								controls
								autoplay
							></audio>
							<AudioStrength count={appContext.audioLevel} />
						</div>
					</div>
				</div>
				<div className={classes.startLiveClass}>
					<button onClick={startLiveClass}>Start Live Class</button>
				</div>
				{/* <button className="btn btn-lg btn-primary" onClick={()=>getStream('video')}>Grab video</button>
            <button className="btn btn-lg btn-primary" onClick={()=>getStream('audio')}>Grab audio</button> */}
			</ModalBody>
		</Modal>
	);
}
