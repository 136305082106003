import React, { useState, useEffect, useContext } from "react";
import { Checkbox } from "semantic-ui-react";
import { AppContext } from "../../context/AppContext";
import { v4 as uuidv4 } from "uuid";
export default function Settings() {
  const appContext = useContext(AppContext);
  const disableStudentInputSocket = (isRaiseHand) => {
    if(isRaiseHand){
      appContext.setContext({
        disableRaiseHand: !appContext.disableRaiseHand
      })
    }
    else{
      appContext.setContext({
        disableChat: !appContext.disableChat
      })
    }
  };

  useEffect(()=>{
      if(appContext.disableRaiseHand)
      {
        changeType("STOP_RAISE_HAND")
      }
      else
      {
        changeType("ALLOW_RAISE_HAND")
      }
  },[appContext.disableRaiseHand])
  useEffect(()=>{
      if(appContext.disableChat)
      {
        changeType("STOP_CHAT_MESSAGES")
      }
      else
      {
        changeType("ALLOW_CHAT_MESSAGES")
      }
  },[appContext.disableChat])

  const changeType = (type) => {
    let { rtm } = appContext;
    let { sessionData } = appContext;
    let user = appContext.getUserInfo();

    let chat = {
      _id: uuidv4(),
      message: "",
      type,
      sent_at: parseInt(Date.now()),
      user: user,
    };
    rtm
      .sendChannelMessage(chat, sessionData.channelName)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  }


  const onSubmitHandler = (text) => {
    let { rtm } = appContext;
    let { sessionData } = appContext;
    let user = appContext.getUserInfo();
    let chat = {
      _id: uuidv4(),
      message: text,
      type: "MESSAGE",
      sent_at: parseInt(Date.now()),
      user: user,
    };

    let oldMessage = [user.name, text].join("%--%--%");
    rtm.sendChannelMessage(oldMessage, sessionData.channelName);
    rtm
      .sendChannelMessage(chat, sessionData.channelName)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  };
  
  return (
    <div className="settingsTab">
      <div
        className="settingsTab__item"
        style={{ borderTop: "1px solid #dbdbdb" }}
      >
        <div className="settingsTab__item--content">
          <div className="settingsTab__item--content--head">
            Replies in Chat
          </div>
          <div className="settingsTab__item--content--desc">
            Turning OFF will turn off the replies in chat of this live session
          </div>
        </div>
        <Checkbox
          checked={!appContext.disableChat}
          toggle
          onChange={() => {
            disableStudentInputSocket(false);
          }}
        />
      </div>
      <div
        className="settingsTab__item"
        style={{ borderTop: "1px solid #dbdbdb" }}
      >
        <div className="settingsTab__item--content">
          <div className="settingsTab__item--content--head">
            Raise Hand
          </div>
          <div className="settingsTab__item--content--desc">
            Turning OFF will turn off the raise hands by students
          </div>
        </div>
        <Checkbox
          toggle
          checked={!appContext.disableRaiseHand}
          onChange={() => {
            disableStudentInputSocket(true);
          }}
        />
      </div>
    </div>
  );
}