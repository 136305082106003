import React, { useContext, useEffect } from "react";
import { AppContext } from "./../context/AppContext";
import Button from "./Button/Button";
import { toast } from "react-toastify";
import { IconHand } from "../utils/Icons";
import classes from "../containers/Controls/Controls.module.css";

export default function StudentRaiseHandControls() {
  const appContext = useContext(AppContext);
  const { userType, studentInvitation, sessionData , stopRaiseHand } = appContext;
  //   lifecycleEvents

  useEffect(() => {
    let { rtm, setContext  } = appContext;
    rtm.on(
      "closeRaiseHandConnection",
      ({ memberId, sendConnectionClosedMessageToTutor }) => {
        //your logic here
        setContext({
          studentInvitation: {
            status: "closed",
          },
        });
        console.log("connection closed");
        //this function sends back message to tutor of closed connection notification
        sendConnectionClosedMessageToTutor(memberId);
      }
    );
  }, []);

  

  const raiseHand = () => {
    if(appContext.stopRaiseHand){
      return toast.info("Tutor has disabled raise hand")
    }
    const { rtm, sessionData } = appContext;

    let invitation = rtm.client.createLocalInvitation(
      `${sessionData.tutorUserId}`
    );
    invitation.send();

    appContext.setContext((prevState) => {
      return {
        studentInvitation: {
          invitation,
          handRaised: true,
          status: "pending",
        },
      };
    });

    // add all event listeners
    invitation.on("LocalInvitationAccepted", (response) => {
      // console.log("LocalInvitationAccepted", response);

      toast.success("You are now speaking...");

      appContext.setContext((prevState) => {
        return {
          studentInvitation: {
            ...prevState.studentInvitation,
            status: "accepted",
          },
        };
      });

      // appContext.rtm.emit("raiseHandInviteAccepted");
    });

    invitation.on("LocalInvitationCanceled", (response) => {
      // console.log("LocalInvitationCanceled", response);
      //   add toaster of invite cancelled here
      toast.error("Raise hand request cancelled");

      appContext.setContext({
        studentInvitation: {},
      });
    });

    invitation.on("LocalInvitationFailure", (response) => {
      // console.log("LocalInvitationFailure", response);
      //   add toaster of invite faliure, ask to retry
      toast.error("Unable to raise hand, Retry again");

      appContext.setContext({
        studentInvitation: {},
      });
    });

    invitation.on("LocalInvitationReceivedByPeer", (response) => {
      // console.log("LocalInvitationReceivedByPeer", response);
      toast.info("Raise hand request sent");

      appContext.setContext((prevState) => {
        return {
          studentInvitation: {
            ...prevState.studentInvitation,
            status: "sent",
          },
        };
      });
    });

    invitation.on("LocalInvitationRefused", (response) => {
      //   add toaster of invite rejected by tutor, ask to retry
      toast.error("Tutor has rejected your request");

      // console.log("LocalInvitationRefused", response);
      appContext.setContext({ studentInvitation: {} });
    });
  };

  // cancel invite
  const cancelRaiseHand = function () {
    const { studentInvitation } = appContext;
    if (!studentInvitation.handRaised) return;

    // console.log("cancelling  invite");
    // add invite cancel toaster here
    // console.log(studentInvitation.invitation);
    studentInvitation.invitation.cancel();
    appContext.setContext({ studentInvitation: {} });
  };

  const endRaiseHandConnection = function () {
    const { studentInvitation } = appContext;
    // console.log(studentInvitation);
    if (!studentInvitation.handRaised) return;

    appContext.rtm.emit("raiseHandClosed", appContext.persistedData.user.id);
    // add  connection close toaster here

    appContext.setContext({
      studentInvitation: {
        status: "closed",
      },
    });
  };

  if (sessionData.liveVersion == 1) return null;

  if (!studentInvitation.handRaised) {
    return (
      <Button onClick={raiseHand} className={classes.Button}>
        <IconHand color="#333333" />
      </Button>
    );
  }
  let { status, handRaised } = studentInvitation;
  // console.log({ status });
  return (
    <React.Fragment>
      {userType === "student" && !handRaised && (
        <Button onClick={raiseHand} className={classes.Button}>
          <IconHand color="#333333" />
        </Button>
      )}

      {userType === "student" && handRaised && (
        <Button
          onClick={
            status === "pending" || status === "sent"
              ? cancelRaiseHand
              : endRaiseHandConnection
          }
          className={classes.Button + " " + classes.ButtonEnd}
        >
          <IconHand color="#ffffff" />
        </Button>
      )}
    </React.Fragment>
  );
}
