export const BASE_URL =
	// "http://dev-testing.classplus.co";
	// &&
	// "https://wl-apip.classplus.co";
	//  &&
	"https://api.classplusapp.com";
//  &&
// "http://dev-testing.classplus.co";
//  &&
// "https://wl-api-2.staging.classplus.co";

// export const LIVE_SOCKET_URL = "https://livepro.teach-r.com:4996"; // Staging
export const LIVE_SOCKET_URL = "https://livebe.teach-r.com:4996"; // PROD
