import React , {useContext , useState} from "react";
import Modal, {
  ModalHeader,
  ModalFooter,
  ModalBody
} from "../../components/Modal/Modal";
import { AppContext } from "../../context/AppContext";
import classes from "./Polls.module.css";
import PollStats from './PollStats'



export default function PollAttempt({})
{
  const appContext = useContext(AppContext);
  const {selectedOption , setContext} = appContext
  const [selection,setSelectiom] = useState("")

  const {pollData={}} = appContext

  return (
    <Modal hidden={!appContext.sendPoll} toggle={()=>{
      appContext.setContext({
        sendPoll: !appContext.sendPoll,
      });
    }}>
        <ModalHeader>
            <div className={classes.modalHeading}>
            Answer Polls
            </div>
        </ModalHeader>
        <ModalBody style={{padding:"0 1rem", maxHeight:"75vh", overflow:"auto"}}>
        { pollData?.duration && pollData.options ? (
            <>
              <div className={classes.questionBody}>{pollData.question}</div>
              <div className={classes.options}>
                {pollData.options.map(option=>{
                  return  <div onClick={()=>setContext({selectedOption:selectedOption==option.index?"":option.index+""})}  className={`${classes.option} ${selectedOption==option.index?classes.selected:""}`}>{option.text?option.text:String.fromCharCode( Number(option.index) + 'A'.charCodeAt(0))}</div>
                })}
              </div>
              <div className={classes.timeLeft}>Time Left : {pollData.duration}s</div>
            </>)
            : (<PollStats/>)
          }
      </ModalBody>
    </Modal>
  );
}
