import React from "react";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import "./ShareModal.css";
import classes from "../../components/Modal/Modal.module.css";

function ShareModal({ closeAction, liveSocialLinks }) {
	let socialLinks;
	if (liveSocialLinks) {
		socialLinks = liveSocialLinks;
	}
	const ShareArray = socialLinks && socialLinks.links;

	const onCopy = () => {
		toast.success("Link Copied");
	};

	return (
		<div className="ShareModal">
			<div className="ShareModal__Content">
				<div className="ShareModal__Content__Top">
					<div className="ShareModal__Content__Top--Title">Share via</div>
					<div
						className="ShareModal__Content__Top--Close"
						onClick={closeAction}
					>
						<img
							src={
								"https://web.classplusapp.com/newApp/static/media/close.c2915c9a.svg"
							}
							width="14px"
							height="14px"
							alt="&#10006;"
						/>
					</div>
				</div>

				<div className="ShareModal__Content__Body">
					<div className="ShareModal__Content__Body--SocialList">
						{ShareArray && ShareArray.length > 0 ? (
							ShareArray &&
							ShareArray.map((link, index) => {
								return (
									<div
										key={index}
										style={{
											width: "40px",
											height: "40px",
											background: "#F0F4F7",
											borderRadius: "20%",
											display: "grid",
											placeItems: "center",
											cursor: "pointer",
										}}
										onClick={() => {
											window.open(link && link.shareUrl, "_blank");
										}}
									>
										<img
											src={link.iconUrl}
											alt="social"
											key={index}
											style={{
												maxWidth: "40px",
												maxHeight: "40px",
											}}
										/>
									</div>
								);
							})
						) : (
							<div style={{ textAlign: "center" }}>Loading...</div>
						)}
					</div>
					<div className="ShareModal__Content__Body--Or">OR</div>
					<div className="ShareModal__Content__Body--CopySection">
						<div className="ShareModal__Content__Body--CopySection--Link">
							{socialLinks && socialLinks.copyUrl}
						</div>
						<CopyToClipboard
							text={socialLinks && socialLinks.copyUrl}
							onCopy={() => {
								onCopy();
							}}
						>
							<div className="ShareModal__Content__Body--CopySection--Copy">
								Copy
							</div>
						</CopyToClipboard>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ShareModal;
