import React , { useContext, useEffect, useState } from "react";
import Modal, {
  ModalHeader,
  ModalFooter,
  ModalBody
} from "../../components/Modal/Modal";
import { AppContext } from "../../context/AppContext";
import classes from "./Polls.module.css";
import PollStats from './PollStats'
import { toast } from "react-toastify";


function PollCreate({})
{
  const appContext = useContext(AppContext);
  const initialState = {
    question:"",
    options:[{
      index:"0",
      text:"",
    },{
      index:"1",
      text:""
    }],
    duration:0
  }

  const [create_poll,setPoll] = useState(initialState)

  const questionChange = (e) => {
    console.log("e",e)
    setPoll({
      ...create_poll,
      question:(e.target.value+'').substring(0,60)
    })
  }

  const optionChange = (e) => {
    let options = [...create_poll.options]
    options[e.target.name].text = (e.target.value+'').substring(0,60)
    setPoll({
      ...create_poll,
      options
    })
  }

  const handleKeyDown = (event,index) => { 
    console.log("event.keyCode",event.keyCode)
    if(index!==undefined && create_poll.options[index].text.length==60 && event.keyCode!==8 ){
      event.preventDefault()
    }
    if (create_poll.question.length==60 && event.keyCode !== 8 && index==undefined) {
      event.preventDefault()
    }
  };

  const pushOption = () => {
    let options = [...create_poll.options]
    options.push({index:options.length,text:""})
    setPoll({
      ...create_poll,
      options
    })
  }

  const removeOption = (index) => {
    let options = [...create_poll.options]
    options.splice(index, 1);
    options.forEach((option,index)=>{
      option.index=index+""
    })
    setPoll({
      ...create_poll,
      options
    })
  }

  const toggleTime = (time) => {
    let duration = create_poll.duration;
    duration = time == duration ? 0 : time;
    setPoll({
      ...create_poll,
      duration
    })
  } 

  const setPollToContext = () => {
    if(!window.navigator.onLine){
      return toast.warn("You are offline....")
    }
    if(!create_poll.duration){
      return toast.warn("Please select poll length")
    }
    appContext.setContext({
      currentPoll:create_poll,
      pollData:{duration:create_poll.duration , showPoll:true}
    })
    setPoll(initialState)
  }

  useEffect(()=>{
    console.log("CALLED")
  },[])

  const selectedDuration = {
    background: "rgba(0, 154, 224, 0.1)",
    border: "1px solid #009AE0",
  }

  const disabledStyle= appContext?.pollData?.duration > 0 ? {background:"#C4C4C4",cursor:"not-allowed"} :{}

  const createPollClick = () => {
    if(!window.navigator.onLine){
      return toast.warn("You are offline....")
    }
    appContext.setContext({pollData:{...appContext.pollData,showPoll:false}})
  }
  

  return (    
    <Modal style={{zIndex:"111111"}} hidden={!appContext.createPoll} toggle={()=>{
      appContext.setContext({
        createPoll: !appContext.createPoll,
      });
    }}>
        <ModalHeader>
          <div className={classes.modalHeading} >Create Polls</div>
        </ModalHeader>
      <ModalBody style={{maxHeight:"65vh",overflow:"auto"}}>
        {!(appContext?.pollData?.showPoll) && <>
        <form>
        <div className={classes.questionBox}>
          <input key={"question_@!@"} 
          onChange={questionChange} 
          onKeyDown={handleKeyDown}
          maxlength="60"
          className={classes.InputFields} style={{paddingRight:"4rem"}} value={create_poll.question} placeHolder={""} />
          <span className={classes.questionLengthCount}>{create_poll.question.length}/60</span>
        </div>
        <div>
          <div className={classes.choices}>Choices</div>
          <div className={classes.choicesInput}>
          {
            create_poll.options.map((option,index)=>{
             return <div style={{position:"relative"}}>{index<2?(
                <input 
                  key={"option_22"+index}  
                  className={classes.InputFields}  
                  placeHolder={"Choice "+(index+1)} 
                  name={index+""} 
                  onChange={optionChange}
                  onKeyDown={(e)=>handleKeyDown(e,index)}
                  value={option.text}  
                />):(
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                  <input 
                      key={"option_22"+index} 
                      style={{width:"calc(100% - 30px)"}} 
                      className={classes.InputFields} 
                      name={index+""} 
                      onChange={optionChange}
                      onKeyDown={(e)=>handleKeyDown(e,index)}
                      value={option.text}
                      placeHolder={"Choice "+(index+1)+" (Optional)"}
                     />
                  <img onClick={()=>removeOption(index)} style={{marginBottom: "1.5rem", cursor:"pointer"}} src={"https://test-portal-assets.s3.ap-south-1.amazonaws.com/staging_1623674938893/c08aa1c5-602a-47ae-890f-02ad6055f5f8.png"}/>
                </div>
              )}
              <span className={classes.optionLength} style={index<2?{}:{right:"3.2rem"}}>{option.text.length}</span>
              </div>
            })
          }
          </div>
        </div>
        </form>
        <div onClick={pushOption} className={classes.addNew}>
          + Add New 
        </div>
        <div>
          <div className={classes.PollLength}>
            Poll Length
          </div>
          <div style={{marginBottom:"0"}} className={classes.PollLength}>
            <span style={create_poll.duration==30?selectedDuration:{}} onClick={()=>toggleTime(30)}>30s</span>
            <span style={create_poll.duration==60?selectedDuration:{}} onClick={()=>toggleTime(60)}>60s</span>
            <span style={create_poll.duration==120?selectedDuration:{}} onClick={()=>toggleTime(120)}>2m</span>
            <span style={create_poll.duration==300?selectedDuration:{}} onClick={()=>toggleTime(300)}>5m</span>
          </div>
        </div>
        </>}
        {!!appContext?.pollData?.showPoll && <PollStats/>}
      </ModalBody>
      <ModalFooter style={{padding:"1rem"}}>
        <div>
           {(appContext?.pollData?.showPoll) &&
           <button disabled={appContext?.pollData?.duration > 0} onClick={createPollClick} style={disabledStyle} className={classes.pollCreateButton}>
            Create New Poll
          </button>
          }
          {
            !appContext?.pollData?.showPoll && <button onClick={setPollToContext}  className={classes.pollCreateButton}>
            Send Poll
          </button>}
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default React.memo(PollCreate);
