import React, { useEffect , useContext } from 'react';
import classes from './Toaster.module.css'
import { AppContext } from "../../context/AppContext";
export default function Toaster(props) {
    const appContext = useContext(AppContext);
    return (appContext.toasters&&appContext.toasters.length?<div className={classes.toasters}>
        {
            appContext.toasters.map(e=>{
                return <div className={classes[e.type] + " " + classes.toaster}>
                    {e.name}
                </div>
            })
        }
    </div>:<></>)
}
