import React from "react";
import Modal, {
	ModalHeader,
	ModalFooter,
	ModalBody,
} from "../../components/Modal/Modal";
import classes from "../../components/Modal/Modal.module.css";

export default function CloseModal({
	hidden,
	toggle,
	handleSuccess,
	handleClose,
	showLeaving,
	appContextData,
}) {
	let { userType } = appContextData;
	return (
		<Modal hidden={hidden} toggle={toggle}>
			{!showLeaving ? (
				<ModalHeader>
					<p style={{ fontWeight: "medium" }}>
						Are you sure want to {userType == "tutor" ? "end" : "leave"} the
						class "{appContextData.sessionData.title}"
					</p>
				</ModalHeader>
			) : (
				<React.Fragment></React.Fragment>
			)}
			<ModalBody>
				{!showLeaving ? (
					<div className={classes.ModalActions}>
						<button className={classes.btnPrevent} onClick={toggle}>
							Stay and {userType == "tutor" ? "Teach" : "Learn"}
						</button>
						<button
							className={classes.btnSucceed}
							style={{ marginLeft: "16px" }}
							onClick={() => {
								localStorage.removeItem("activeMicId");
            					localStorage.removeItem("activeCameraId")
								handleSuccess();
								// handleClose();
							}}
						>
							Leave Class
						</button>
					</div>
				) : (
					<p style={{ fontWeight: "bold" }}>
						Please wait we are ending your class ...
					</p>
				)}
			</ModalBody>
			{/* <ModalFooter>Confirmation Footer</ModalFooter> */}
		</Modal>
	);
}
