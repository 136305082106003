import { LIVE_SOCKET_URL } from "../../appConfig";
import io from "socket.io-client";
import React, { Component } from "react";

let jwplayer = window.jwplayer;

export default class PollController extends Component {
	userID = "";
	_logined = false;
	isLive = false;
	sessionData = {};
	persistedData = {};
	session = {};
	devices = {
		cameras: [],
		mics: [],
	};
	twoWayEnabled = false;
	userType = "";
	OV = undefined;
	publisher = undefined;
	videoSession = undefined;
	cameraVideoTrack = undefined;
	screenVideoTrack = undefined;
	socket = undefined;
	isSocketConnected = false;
	sessionType = "ams";

	constructor() {
		super();
	}

	init = () => {
		this.sessionData = this.props.appContext.sessionData;
		this.persistedData = this.props.appContext.persistedData;
		this.userType = this.props.appContext.userType;

		// jwplayer.key = "maVKnN8YJiCGaWsCy6xa5KoV45doYy/wTBqv49tFdVg=";

		let liveSessionType = this.sessionData.liveSessionType;
		console.log(liveSessionType, "^^^^^^^liveSessionType");
		if (liveSessionType) {
			this.sessionType = liveSessionType;
		}

		this.initSocket();
		// this.getDevices();
		var self = this;
		console.log("-=-=-=-=-=");

		if (this.userType != "tutor") {
			setTimeout(function () {
				self.socket.emit("get_students", {
					sessionId: self.sessionData.liveSessionId + "",
				});
			}, 3000);
		}
	};
	initSocket = () => {
		var self = this;
		this.socket = io(LIVE_SOCKET_URL, {
			reconnectionDelay: 1000,
			reconnection: true,
			reconnectionAttempts: Infinity,
			jsonp: false,
			forceNew: true,
			transports: ["websocket"],
		});
		// console.log("VideoHandler :: socket : ", socket);
		this.socket.on("connect", () => {
			this.props.appContext.setContext({
				amsLiveSocket: this.socket,
			});
			this.socket.emit("join_room", {
				sessionId: this.sessionData.liveSessionId + "",
				isAgora: true,
			});
			console.log("COnnectedðdada");
			this.socket.on("poll_created", (data) => {
				console.log("poll_created", data);
				if (this.props.appContext.userType == "tutor") {
					let currentPoll = { ...data, created: true };
					if (data.results && currentPoll.options) {
						data.results.forEach((option) => {
							currentPoll.options[option.index].percent = option.percent;
						});
					}
					this.props.appContext.setContext({
						currentPoll,
						pollData: { duration: data.duration, showPoll: true },
					});
				} else
					this.props.appContext.setContext({
						pollData: data,
						sendPoll: true,
						selectedOption: "",
					});
			});

			let event_name =
				this.userType != "tutor" ? "poll_results" : "instant_poll_results";

			this.socket.on(event_name, (data) => {
				if (this.userType == "tutor") {
					let newPollStats = { ...this.props.appContext.pollStats };
					newPollStats.options = [
						...(newPollStats.options ? newPollStats.options : []),
					];
					newPollStats.options.forEach((option) => {
						option.increasing = false;
					});
					data.forEach((option) => {
						newPollStats.options[option.index].percent = option.percent;
						newPollStats.options[option.index].increasing = true;
					});
					this.props.appContext.setContext({
						pollStats: newPollStats,
					});
				} else {
					let newPollStats = {};
					newPollStats.options = [...this.props.appContext.pollData.options];
					newPollStats.options.forEach((option) => {
						option.increasing = false;
					});
					data.forEach((option) => {
						newPollStats.options[option.index].percent = option.percent;
						newPollStats.options[option.index].increasing = true;
					});
					this.props.appContext.setContext({
						pollStats: newPollStats,
					});
				}
			});
		});
		this.socket.on("disconnect", function () {});

		this.socket.on("chat_msgs", (data) => {});
	};

	componentDidMount() {
		console.log("componentDidMount-----");
		this.init();
	}

	render() {
		return <></>;
	}
}
