import React, { Component, useContext } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import classes from "./Modal.module.css";
// import Aux from "../../../hoc/_Aux/_Aux";
// import Backdrop from "../Backdrop/Backdrop";
import { enableDocumentScrolling, disableDocumentScrolling } from "./util.js";
// import closeImg from "../../../assets/icons/cross_black.svg";

export const ModalContext = React.createContext();

class Modal extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.hidden !== this.props.hidden ||
      nextProps.hardUpdate ||
      (!nextProps.hidden && !this.props.hidden) ||
      false
    );
  }

  handleEsc = evt => {
    if (evt.keyCode === 27) {
      this.props.toggle && this.props.toggle(evt);
    }
  };

  componentDidMount() {
    if (!this.props.hidden) disableDocumentScrolling();
  }

  componentWillMount() {
    this.container = document.createElement("div");
    document.body.append(this.container);
  }

  componentWillUnmount() {
    // window.removeEventListener("keyup", this.handleEsc);
    this.container.remove();
    if (!this.props.hidden) enableDocumentScrolling();
  }

  componentDidUpdate() {
    if (!this.props.hidden) {
      disableDocumentScrolling();
      let elem = this._modalRef.getElementsByTagName("input")[0];
      // window.addEventListener("keyup", this.handleEsc);
      // elem && elem.focus();
    } else {
      enableDocumentScrolling();
      // window.removeEventListener("keyup", this.handleEsc);
    }
  }

  render() {
    let hidden = "";
    if (this.props.hidden) {
      hidden = classes.Hidden;
    }
    return (
      <ModalContext.Provider value={{ toggle: this.props.toggle }}>
        {createPortal(
          <div
            className={
              classes.Container +
              " " +
              hidden +
              " " +
              (this.props.className ? this.props.className : "")
            }
          >
            <div
              className={classes.Modal}
              style={this.props.style || {}}
              ref={elem => (this._modalRef = elem)}
            >
              {this.props.children}
            </div>
          </div>,
          this.container
        )}
      </ModalContext.Provider>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.any,
  hidden: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool,
  toggle: PropTypes.func,
  hardUpdate: PropTypes.bool,
  style: PropTypes.object
};

export default Modal;

export function ModalHeader(props) {
  let className = classes.Header;
  let { toggle } = useContext(ModalContext) || {};
  if (props.className) className += " " + props.className;
  return (
    <div className={classes.HeaderContainer}>
      <div className={className} style={props.style}>
        {props.children}
      </div>
      <button className={classes.CloseBtn} onClick={toggle}>
        ×
      </button>
    </div>
  );
}

ModalHeader.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string
};

export function ModalBody(props) {
  let className = classes.Body;

  if (props.className) className += " " + props.className;

  return (
    <div className={className} style={props.style}>
      {props.children}
    </div>
  );
}

ModalBody.propTypes = {
  children: PropTypes.node
};

export function ModalFooter(props) {
  let className = classes.Footer;

  if (props.className) className += " " + props.className;

  return (
    <div className={className} style={props.style}>
      {props.children}
    </div>
  );
}

ModalFooter.propTypes = {
  children: PropTypes.node
};
