import React, { useContext } from "react";
import classes from "./Polls.module.css";
import { AppContext } from "../../context/AppContext";

export default function PollStats({}) {
	const appContext = useContext(AppContext);
	const { pollData = {}, pollStats = { options: [] } } = appContext;

	console.log("pollStats", pollStats);

	return (
		<div>
			<div className={classes.questionBody}>{pollStats.question}</div>
			<div className={classes.bars}>
				{pollStats.options.map((option) => {
					return (
						<div className={classes.bar}>
							<div className={classes.statsBar}>
								<span
									style={{
										width: (option.percent ? option.percent : "0.5") + "%",
									}}
									className={
										appContext.selectedOption == option.index
											? classes.blue
											: classes.grey
									}
								></span>
								<span className={classes.questionText}>
									{String.fromCharCode(
										Number(option.index) + "A".charCodeAt(0)
									)}
									. {option.text}
								</span>
							</div>
							<span className={classes.percentage}>
								{option.percent ? option.percent : "0"}%
							</span>
						</div>
					);
				})}
			</div>
			<div
				className={classes.timeLeft}
				style={pollData.duration ? {} : { color: "#FF4058", fontWeight: "500" }}
			>
				{pollData.duration ? `Time Left : ${pollData.duration}s` : "Time Over"}
			</div>
		</div>
	);
}
