import classes from "../Live.module.css";
import React, { useEffect,useContext } from "react";
import { Loader } from "semantic-ui-react";
import { AppContext } from "../context/AppContext";
import { Wifi } from "../utils/Icons";

export default function RtmConnectionStatus(props) {
	const appContext = useContext(AppContext);
  let { connectionState, retryRtmConnection } = props;
  useEffect(()=>{
    if(connectionState === "RECONNECTING"){
      appContext.addTimedToaster({
        name:<span style={{display:"flex",justifyContent:"space-between"}}>
          <span><Wifi/></span>
          {appContext.userType=="tutor"?<span>
            Seems like your internet connection is slow. 
              Your students might not be able to hear you properly.
          </span>:
          <span>
           Seems like your internet connection is slow. 
            Please connect again to join the class.
          </span>}
        </span>,
        time:10000,
        type:"warning"
      })
    }
  },[connectionState])

  return connectionState ? (
    <div
      className={`${classes.connectionInfo}  ${
        connectionState !== "CONNECTED"
          ? classes.error
          : connectionState === "CONNECTED"
          ? classes.success
          : null
      } `}
    >
      {connectionState === "RECONNECTING" ? (
        <div>
          {" "}
          <Loader active inline inverted size="small" />{" "}
          <span> Reconnecting...</span>
        </div>
      ) : null}

      {connectionState === "CONNECTING" ? (
        <div>
          {" "}
          <Loader active inline inverted size="small" />{" "}
          <span> Connecting...</span>
        </div>
      ) : null}
      {connectionState === "CONNECTED" ? <div>Connected</div> : null}
      {connectionState !== "CONNECTED" && connectionState !== "RECONNECTING" ? (
        <div>
          {connectionState === "ABORTED" && (
            <span>
              Disconnected! You may have logged in from other location.{" "}
            </span>
          )}
          {connectionState !== "ABORTED" && <span>Unable to connect! </span>}
          <button className={classes.btnLinkRetry} onClick={retryRtmConnection}>
            Reload
          </button>
        </div>
      ) : null}
    </div>
  ) : null;
}
