import React , {useContext , useEffect , useRef, useState} from "react";
import { AppContext } from "../../context/AppContext";

export default function PollTimer({}) {
    const appContext = useContext(AppContext);
    const {pollData={}} = appContext
    useEffect(()=>{
        if(pollData && pollData.duration>0)
            setTimeout(()=>{
                let newPollData= {...pollData}
                newPollData.duration = newPollData.duration - 1;
                appContext.setContext({pollData:newPollData})
                // console.log("TIME" , pollData.duration )
            },1000)
        // if(pollData && appContext.sendPoll && !pollData.duration){
        //     appContext.setContext({
        //         sendPoll: !appContext.sendPoll,
        //         pollData:{}
        //       });
        // }
    },[pollData.duration])
    return <></>
}