import { AppContext } from "../../context/AppContext";
import React, { useContext, useMemo } from "react";
import AgoraRTC from "agora-rtc-sdk";
import EventEmitter from "events";
import { BASE_URL } from "../../appConfig";
import {
	isSafari,
	isCompatibleChrome,
	isFirefox,
} from "./../../utils/rtcUtils";

window.AgoraRTC = AgoraRTC;
export default class AgoraClient extends EventEmitter {
	constructor() {
		super();
	}
	channels = {};
	userID = "";
	_logined = false;
	sessionData = {};
	persistedData = {};
	client = {};
	cameraVideoProfile = "720p_3";
	localStreams = {
		uid: "",
		camera: {
			camId: "",
			micId: "",
			stream: {},
		},
		screen: {
			stream: {},
		},
	};
	remoteStreams = {};
	devices = {
		cameras: [],
		mics: [],
	};
	userType = "";
	init = (sessionData, persistedData, userType) => {
		if (sessionData && sessionData.cameraProfile) {
			this.cameraVideoProfile = sessionData.cameraProfile;
		}
		this.sessionData = sessionData;
		this.persistedData = persistedData;
		this.userType = userType;
		this.initializeRtc();
	};
	remoteStreams = [];
	initializeRtc = () => {
		let { agoraId: agoraAppId } = this.sessionData;
		if (!agoraAppId) return console.error("agoraAppId not found");
		console.log(AgoraRTC.checkSystemRequirements(), "isBrowserCompatible?");
		this.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
		AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.DEBUG);
		AgoraRTC.Logger.enableLogUpload();
		this.subscribeClientEvents();
		this.client.init(
			agoraAppId,
			() => {
				this.joinChannel(); // join channel upon successfull init
			},
			(err) => {
				console.log("[ERROR] : AgoraRTC client init failed", err);
			}
		);
	};
	join = () => {
		let { agoraId: token, channelName, uid: userID } = this.sessionData;
		this.client.join(
			token,
			channelName,
			Number(userID) ? Number(userID) : 2345678,
			(uid) => {
				if (this.userType == "tutor") {
					this.createCameraStream();
				}
				this.localStreams.uid = uid;
			},
			(err) => {
				console.log("[ERROR] : join channel failed", err);
				if ("DYNAMIC_KEY_EXPIRED" == err) {
					this.emit("showModal", { type: "DYNAMIC_KEY_EXPIRED" });
				}
			}
		);
	};
	joinChannel = () => {
		this.setClientRole(
			this.userType == "tutor" ? "host" : "audience",
			this.join
		);
	};
	leaveChannel = () => {};
	createScreenStream = () => {
		let { uid: userID, screenShareProfile } = this.sessionData;
		let streamSpec = {
			streamID: userID,
			audio: true,
			video: false,
			screen: true,
			fit: "contain",
		};
		let screenVideoProfile = screenShareProfile ? screenShareProfile : "720p_1";
		if (isFirefox) {
			streamSpec.mediaSource = "window";
		} else if (!isCompatibleChrome()) {
			streamSpec.extensionId = "minllpmhdgpndnkomcoccfekfegnlikg";
		}
		let newScreenStream = AgoraRTC.createStream(streamSpec);
		newScreenStream.setScreenProfile(screenVideoProfile);

		this.localStreams.screen.stream = newScreenStream;
		newScreenStream.init(
			() => {
				this.publishScreenStream();
			},
			(err) => {
				console.log(err);
				this.emit("AgoraError", err);
			}
		);
	};
	createCameraStream = () => {
		let { uid: userID } = this.sessionData;
		if (
			this.localStreams.camera.stream &&
			this.localStreams.camera.stream.isPlaying
		) {
			this.publishCameraStream();
		}
		var mediaConstraints = {
			audio: true,
		};
		// console.log(this.userType, "++++++++this.userType");
		if (this.userType == "tutor")
			mediaConstraints.video = { width: 1280, height: 720 };
		if (localStorage.getItem("activeCameraId")) {
			mediaConstraints.video.deviceId = localStorage.getItem("activeCameraId");
		}
		if (localStorage.getItem("activeMicId")) {
			mediaConstraints.audio = {
				deviceId: localStorage.getItem("activeMicId"),
			};
		}

		navigator.mediaDevices
			.getUserMedia(mediaConstraints)
			.then((mediaStream) => {
				var videoSource = mediaStream.getVideoTracks()[0];
				var audioSource = mediaStream.getAudioTracks()[0];
				// After processing videoSource and audioSource
				// console.log(audioSource, "++++++audiosource", videoSource);
				let ifShowVideo = this.userType == "tutor" ? true : false;
				if (ifShowVideo) {
					var localStream = AgoraRTC.createStream({
						video: true,
						audio: true,
						videoSource: videoSource,
						audioSource: audioSource,
						streamID: userID,
						screen: false,
						mirror: false,
					});
				} else {
					var localStream = AgoraRTC.createStream({
						video: false,
						audio: true,
						audioSource: audioSource,
						streamID: userID,
						screen: false,
						mirror: false,
					});
				}

				localStream.on("accessDenied", (evt) => {
					console.log("accessDenied-=-=", evt);
				});
				this.localStreams.camera.stream = localStream; // keep track of the camera stream for later
				this.localStreams.camera.stream.init(
					() => {
						window.appContext.micStatus &&
							window.appContext.agoraClient.localStreams.camera.stream.muteAudio();
						window.appContext.isVideoSharing &&
							window.appContext.agoraClient.localStreams.camera.stream.muteVideo();

						this.publishCameraStream();
					},
					(err) => {
						console.log("[ERROR] : getUserMedia failed", err);
						this.emit("AgoraError", err);
					}
				);
				localStream.setVideoProfile(this.cameraVideoProfile);
				localStream.on("accessAllowed", () => {
					if (
						this.devices.cameras.length === 0 &&
						this.devices.mics.length === 0
					) {
						this.userType == "tutor" && this.getCameraDevices();
						this.getMicDevices();
					}
				});
			});

		// let localActiveCameraId = localStorage.getItem("activeCameraId");
		// let localActiveMicId = localStorage.getItem("activeMicId");

		// if (localActiveCameraId) {
		// 	this.changeStreamSource(localActiveCameraId, "video");
		// }

		// if (localActiveMicId) {
		// 	this.changeStreamSource(localActiveMicId, "audio");
		// }
	};
	getCameraDevices = () => {
		let localActiveCameraId = localStorage.getItem("activeCameraId");
		if (localActiveCameraId) {
			this.localStreams.camera.camId = localActiveCameraId;
			this.emit("defaultIds", {
				type: "camera",
				id: localActiveCameraId,
			});
		}
		console.log("Checking for Camera Devices.....");
		try {
			this.client.getCameras((cameras) => {
				// console.log("++++ cameras", cameras);
				this.devices.cameras = cameras; // store cameras array
				// console.log("listOfCameras", cameras);
				cameras.forEach((camera, i) => {
					let deviceId = camera.deviceId;
					if (i === 0 && this.localStreams.camera.camId === "") {
						this.localStreams.camera.camId = deviceId;
						this.emit("defaultIds", {
							type: "camera",
							id: this.localStreams.camera.camId,
						});
					}
				});
				this.emit("cameraDevicesList", cameras);
			});
		} catch (e) {
			console.error("Error---------", e);
		}
	};

	getMicDevices = () => {
		let localActiveMicId = localStorage.getItem("activeMicId");
		// console.log("Checking for Mic Devices.....");
		this.client.getRecordingDevices((mics) => {
			this.devices.mics = mics; // store mics array
			mics.forEach((mic, i) => {
				let name = mic.label.split("(")[0];
				let optionId = "mic_" + i;
				let deviceId = mic.deviceId;
				if (i === 0 && this.localStreams.camera.micId === "") {
					this.localStreams.camera.micId = deviceId;
					this.emit("defaultIds", {
						type: "mic",
						id: localActiveMicId || deviceId,
					});
				}
				if (name.split("Default - ")[1] != undefined) {
					name = "[Default Device]"; // rename the default mic - only appears on Chrome & Opera
				}
			});
			this.emit("micDevicesList", mics);
		});
	};

	changeStreamSource = (deviceId, deviceType) => {
		this.unpublishCameraStream();
		this.closeCameraStream();
		this.createCameraStream();
		// console.log(
		// 	"Switching stream sources for: " +
		// 		deviceId +
		// 		".....DeviceType" +
		// 		deviceType
		// );

		// console.log(this.localStreams, "DEVICE__LocalStreams");

		// var mediaObj = await navigator.mediaDevices.getUserMedia({
		//   video: { deviceId: { exact: activeCameraId } },
		//   audio: { deviceId: { exact: activeMicId }, echoCancellation: true }
		// });
		// let newVideoTrack = mediaObj.getVideoTracks()[0];

		// this.localStreams.camera.stream.switchDevice(
		// 	deviceType,
		// 	deviceId,
		// 	() => {
		// 		console.log(
		// 			"successfully switched to new device with id: " +
		// 				JSON.stringify(deviceId)
		// 		);
		// 		// set the active device ids
		// 		if (deviceType === "audio") {
		// 			console.log("DEVICE__LocalStreams__audio" + JSON.stringify(deviceId));
		// 			this.localStreams.camera.micId = deviceId;
		// 		} else if (deviceType === "video") {
		// 			console.log("DEVICE__LocalStreams__video", this.localStreams);
		// 			this.localStreams.camera.camId = deviceId;
		// 		} else {
		// 			console.error("unable to determine deviceType: " + deviceType);
		// 		}
		// 	},
		// 	() => {
		// 		console.error(
		// 			"failed to switch to new device with id: " + JSON.stringify(deviceId)
		// 		);
		// 	}
		// );
	};

	subscribeStreamEvents = (stream) => {
		[
			"accessDenied",
			"stopScreenSharing",
			"videoTrackEnded",
			"audioTrackEnded",
			"audioMixingPlayed",
			"audioMixingFinished",
			"player-status-change",
		].forEach((eventName) => {
			stream.on(eventName, (...args) => {
				console.log("stream--" + eventName, ...args);
				// log event message
				this.emit("stream--" + eventName, ...args);
			});
		});
	};

	subscribe = (stream) => {
		this.client.subscribe(stream, function (err) {
			console.error("Error while publishing stream", err);
			console.log("[ERROR] : subscribe stream failed", err);
		});
		this.subscribeStreamEvents(stream);
	};

	publishScreenStream = () => {
		this.client.publish(this.localStreams.screen.stream, (err) => {
			console.log("[ERROR] : publish local stream error: " + err);
		});
	};
	publishCameraStream = () => {
		this.client.publish(this.localStreams.camera.stream, (err) => {
			console.log("[ERROR] : publish local stream error: " + err);
		});
	};
	unpublishScreenStream = () => {
		this.client.unpublish(this.localStreams.screen.stream); // clean up and close the screen stream
	};
	unpublishCameraStream = () => {
		this.client.unpublish(this.localStreams.camera.stream);
	};
	closeCameraStream = () => {
		this.localStreams.camera.stream.unmuteVideo();
		this.localStreams.camera.stream.unmuteAudio();
		this.localStreams.camera.stream.stop(); // stop the screen stream playback
		this.localStreams.camera.stream.close(); // clean up and close the screen
	};
	closeScreenStream = () => {
		this.localStreams.screen.stream.stop(); // stop the screen stream playback
		this.localStreams.screen.stream.unmuteAudio();
		this.localStreams.camera.stream.unmuteVideo();
		this.localStreams.screen.stream.close();
	};
	setClientRole = (type, cb) => {
		this.client.setClientRole(
			type,
			() => {
				console.log("Client role set as host.");
				if (typeof cb == "function") {
					cb();
				}
			},
			(e) => {
				console.log("setClientRole failed", e);
			}
		);
	};
	setStreamControl = (eventType = { type: "", changedValue: false }) => {};
	getSessionStats = () => {
		let Duration;
		if (this.client) {
			this.client.getSessionStats((stats) => {
				Duration = stats.Duration;
			});
		}
		return Duration;
	};
	subscribeToEvents = () => {};
	// subscribe client events
	subscribeClientEvents() {
		const clientEvents = [
			"ConnectionStateChanged",
			"MessageFromPeer",
			"stream-published",
			"stream-added",
			"stream-removed",
			"liveStreamingStarted",
			"liveStreamingFailed",
			"liveStreamingStopped",
			"liveTranscodingUpdated",
			"streamInjectedStatus",
			"peer-leave",
			"mute-audio",
			"client-banned",
			"unmute-audio",
			"connection-state-change",
			"onTokenPrivilegeWillExpire",
			"onTokenPrivilegeDidExpire",
			"mute-video",
			"unmute-video",
			"stream-subscribed",
			"stream-unpublished",
			"network-quality",
		];
		clientEvents.forEach((eventName, otherParams) => {
			this.client.on(eventName, (...args) => {
				console.log(eventName, args);
				// log event message
				if (eventName === "connection-state-change") {
					if (this.userType == "tutor") {
						if (
							args[0].prevState == "DISCONNECTED" &&
							args[0].curState == "CONNECTING"
						) {
							this.reconnecing = this.reconnecing ? this.reconnecing + 1 : 1;
						}
						if (
							args[0].prevState == "CONNECTING" &&
							args[0].curState == "CONNECTED" &&
							this.reconnecing > 1
						) {
							if (window.appContext.isScreenSharing) {
								this.unpublishScreenStream();
								this.closeScreenStream();
								this.createScreenStream();
							} else {
								this.unpublishCameraStream();
								this.closeCameraStream();
								this.createCameraStream();
							}
						}
					}
					console.log(
						"ConnectionStateChanged------",
						"otherParams",
						otherParams,
						args[0]
					);
				}
				this.emit(eventName, ...args);
			});
		});
	}
}
